import styled, { css } from "styled-components";

// Components
import Text from "../Text";
import MagicModal from "../MagicModal";

// Utils
import { Color, Font, rem, responsive, Opacity } from "../../utils/style";

const contentStyle = css`
  padding: 48px 24px 40px;

  ${responsive.sm`
    padding: 40px;
    max-width: 460px;
    max-height: 614px;
  `};

  ${responsive.md`
    padding: 56px 80px;
    max-width: 774px;
  `};

  ${responsive.lg`
    padding: 56px 100px;
    max-width: 770px;
  `};
`;

const ModalContentWrapper = styled.div`
  border-top: 2px solid ${Color.ritualBlue};
  padding-top: 24px;

  ${responsive.md`
    padding-top: 32px;
  `}
`;

const TitleWrapper = styled.div`
  margin-bottom: 16px;

  ${responsive.sm`
    margin-bottom: 32px;
  `}
`;

const Header = styled.h3`
  margin: 0;

  ${responsive.sm`
    margin-bottom: 16px;
  `}

  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};

  ${responsive.md`
    letter-spacing: -0.4px;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  `};

  i {
    ${Font.dutch};
  }

  a {
    border-bottom: 2px solid ${Color.ritualBlue};
    transition: opacity 0.2s;

    &:hover {
      opacity: ${Opacity.light};
    }
  }
`;

const Body = styled.p`
  ${Font.dutch};
  margin: 0 0 16px 0;
  letter-spacing: 0;
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `};

  a {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const PublicationModal = ({ isOpen, onRequestClose }) => {
  return (
    <MagicModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentStyle={contentStyle}
      mobileFullPage={true}
    >
      <TitleWrapper data-test-title-wrapper>
        <Header>
          <Text
            id="clinical.publication.modal.title"
            defaultMessage="Our gold standard clinical study was published in {link} — a leading, internationally recognized, peer-reviewed journal."
            values={{
              link: (
                <a
                  href="/assets/frontiers-nutrition-study-2021.pdf"
                  target="_blank"
                >
                  <Text
                    id="clinical.publication.frontiers-in-nutrition"
                    defaultMessage="Frontiers In Nutrition"
                  />
                </a>
              ),
            }}
          />
        </Header>
      </TitleWrapper>

      <ModalContentWrapper>
        <Body>
          <Text
            id="clinical.publication.modal.copy1"
            defaultMessage="Getting published in Frontiers in Nutrition means our clinical study passed the rigorous review of experts in nutrition. From the design and implementation, to the accuracy of our results, our clinical study was put through the quality control ringer."
          />
        </Body>
        <Body>
          <Text
            id="clinical.publication.modal.copy2"
            defaultMessage="Having a clinical study published in a peer reviewed journal is one of the highest awards scientific research can achieve."
          />
        </Body>
      </ModalContentWrapper>
    </MagicModal>
  );
};

export default PublicationModal;
