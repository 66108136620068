import { Component } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactTooltip from "react-tooltip";

// Utils
import { Font, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import sanitizeHtml from "isomorphic-html-sanitize";
import { parseMarkdown } from "../../utils/markdown";

// Components
import Container from "../Container";
import Row from "../Row";
import IngredientBenefits from "./IngredientBenefits";
import MagicVideo from "../MagicVideo";
import { Color } from "../../utils/styleDesignSystem";

// Elements
const IngredientGraphWrapper = styled.div.attrs({
  role: "section",
})`
  position: relative;
  margin-bottom: 56px;

  ${responsive.md`
    margin-bottom: 100px;
  `};
`;

const IngredientGraphContainer = styled(Container)`
  position: relative;
  overflow: visible !important;
  padding-top: 84px;

  ${responsive.md`
    padding-top: 124px;
  `};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 4px;
    background-color: ${Color.indigoBlue};

    ${responsive.sm`
      left: 0;
      right: 0;
    `};
  }
`;

const IngredientColumn = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const IngredientTitle = styled.h2`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 500;
  letter-spacing: -0.4px;
  color: ${Color.indigoBlue};
  margin: 0 0 40px;
  max-width: 231px;

  ${responsive.sm`
    max-width: unset;
  `};

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin-bottom: 80px;
  `};
`;

const IngredientSubtitle = styled.p`
  ${Font.circular};
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${Color.indigoBlue};
  margin: 0 0 32px;
  max-width: 231px;

  em {
    ${Font.dutch};
  }

  ${responsive.sm`
    max-width: 341px;
  `};

  ${responsive.md`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin-bottom: 48px;
    max-width: 470px;
  `};
`;

const VideoColumn = styled(IngredientColumn)`
  ${responsive.sm`
    padding-right: 0;
  `};
`;

const VideoWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin: 0;

  ${responsive.sm`
    margin-bottom: 0;
  `};
`;

const LegendColumn = styled(IngredientColumn)`
  ${responsive.sm`
    padding-left: 20px;
    display: flex;
    align-items: flex-end;
  `};

  ${responsive.md`
    padding-left: 26px;
  `};
`;

const Legend = styled.ol`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 16px 0 24px 45px;

  ${responsive.sm`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
    margin-left: 0;
  `};
`;

const IngredientKey = styled.li`
  position: relative;
  margin-right: 20px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${(p) => (p.color ? p.color : "#cccccc")};
    border-radius: 50%;
    margin-right: 8px;
  }

  & > span {
    ${Font.circular};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 500;
    color: ${Color.indigoBlue};

    em {
      ${Font.dutch};
    }
  }

  &:first-child {
    & > span {
      color: rgb(86, 86, 86);
    }
  }

  ${responsive.sm`
    margin-right: 0;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `};

  ${responsive.md`

    &::before {
      width: 22px;
      height: 22px;
      margin-right: 16px;
    }

    & > span {
      font-size: ${rem(14)};
      line-height: ${rem(20)};
    }
  `};
`;

const AdditionalCopyColumn = styled(IngredientColumn)`
  ${responsive.sm`
    padding-left: 15px;
  `};

  & > p {
    ${Font.circular};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 300;
    color: ${Color.indigoBlue};
    margin: 0 45px 24px 0;

    &:last-child {
      margin-bottom: 0;
    }

    ${responsive.sm`
      margin-left: 0;
    `};

    ${responsive.md`
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    `};
  }
`;

const BackgroundImageWrapper = styled.div`
  width: 133px;
  height: 133px;
  overflow: hidden;
  position: absolute;
  top: 156px;
  left: calc(50% + 120px);
  display: flex;
  align-items: center;
  justify-content: center;

  ${responsive.sm`
    width: 340px;
    height: 340px;
    top: 120px;
    right: -120px;
    left: unset;
  `};

  ${responsive.md`
    width: 454px;
    height: 454px;
    top: 228px;
    right: -155px;
  `};

  ${responsive.lg`
    width: 570px;
    height: 570px;
    top: 218px;
    right: -180px;
  `};
`;

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`;

const ToolTipIcon = styled.span`
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    height: 10px;

    ${responsive.md`
      width: 12px;
      height: 12px;
    `};
  }
`;

const ToolTip = styled(ReactTooltip)`
  ${Font.circular};
  color: ${Color.ritualBlue} !important;
  font-size: ${rem(14)} !important;
  font-weight: 300 !important;
  line-height: ${rem(20)} !important;
  max-width: 177px !important;
  padding: 13px !important;
  box-shadow: 0px 2px 6px 0px ${Color.fadedGrey};
  border-radius: 4px !important;
`;

export default class IngredientGraph extends Component {
  convertToMarkdown(copy) {
    const sanitizedCopy = sanitizeHtml(copy, { allowedTags: [] });
    return parseMarkdown(sanitizedCopy);
  }

  render() {
    const { children, ingredient } = this.props;

    if (
      !ingredient ||
      (ingredient && !ingredient.videoDesktop) ||
      (ingredient && !ingredient.videoMobile)
    ) {
      console.error("Video missing from IngredientGraph");
      return null;
    }

    const sanitizedSubtitle = this.convertToMarkdown(
      ingredient.graphSectionContent.subtitle,
    );

    const benefits = {
      title: ingredient.graphSectionContent.benefitsTitle,
      benefits: ingredient.graphSectionContent.benefits,
      desktopImages: ingredient.benefitsDesktopImages,
      backgroundImage: ingredient.backgroundImage,
      benefitsMobileImage: ingredient.benefitsMobileImage,
    };

    const videoStyles = {
      padding: 0,
      margin: 0,
      width: "100%",
      height: "auto",
    };

    const videoProps = {
      muted: true,
      playsInline: true,
      autoPlay: true,
      prelod: "auto",
      controls: false,
    };

    return (
      <IngredientGraphWrapper>
        <IngredientGraphContainer>
          <BackgroundImageWrapper>
            {ingredient.image && (
              <BackgroundImage
                image={getImage(ingredient.image)}
                alt={ingredient.image.description}
                loading="eager"
              />
            )}
          </BackgroundImageWrapper>
          <Row>
            <IngredientColumn className="col-12 col-sm-11 offset-sm-1">
              <IngredientTitle>
                {ingredient.graphSectionContent.title}
              </IngredientTitle>
              <IngredientSubtitle
                dangerouslySetInnerHTML={{ __html: sanitizedSubtitle }}
              />
            </IngredientColumn>
          </Row>
          <Row>
            <VideoColumn className="col-12 col-sm-7">
              <VideoWrapper>
                <MagicVideo
                  {...ingredient.videoMobile}
                  {...videoProps}
                  key={"desktop-video"}
                  className="d-sm-none"
                  videoElementStyle={videoStyles}
                />
                <MagicVideo
                  {...ingredient.videoDesktop}
                  {...videoProps}
                  key={"mobile-video"}
                  className="d-none d-sm-block"
                  videoElementStyle={videoStyles}
                />
              </VideoWrapper>
            </VideoColumn>
            <LegendColumn className="col-12 col-sm-5">
              <Legend>
                {ingredient.graphSectionContent.legend.map((key, i) => {
                  const markdownLabel = this.convertToMarkdown(key.label);
                  return (
                    <IngredientKey key={i} color={key.color}>
                      <span
                        dangerouslySetInnerHTML={{ __html: markdownLabel }}
                      />
                      {key.tooltip && (
                        <>
                          <ToolTipIcon data-tip={key.tooltip}>
                            <Icons.Info
                              $color={Color.white}
                              $background="#b2b0b0"
                              $outline="none"
                            />
                          </ToolTipIcon>
                          <ToolTip place="right" type="light" effect="solid" />
                        </>
                      )}
                    </IngredientKey>
                  );
                })}
              </Legend>
            </LegendColumn>
          </Row>
          <Row>
            <AdditionalCopyColumn className="col-12 col-sm-6 offset-sm-1 pt-sm-4 pt-md-3">
              <p>{ingredient.graphSectionContent.graphDetail}</p>
              {children}
            </AdditionalCopyColumn>
          </Row>
        </IngredientGraphContainer>
        <IngredientBenefits {...benefits} />
      </IngredientGraphWrapper>
    );
  }
}
